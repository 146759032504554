import './footer.scss';
import YammerIcon from '../../../../images/svg/icons/Viva-Engage.svg';
import { FC } from 'react';
import { NJFooter } from '@engie-group/fluid-design-system-react';

const Footer: FC = () => {
  return (
    <NJFooter
      sloganText="Let's Make Data AI-Ready"
      links={[
        {
          text: 'Good use of Agora',
          url: 'https://agora.engie.com/strapi-files/543',
        },
        {
          text: ' Privacy Policy',
          url: 'https://agora.engie.com/strapi-files/448',
        },
        {
          text: 'Common Data Hub',
          url: 'https://commondatahub.engie.com/',
        },
        {
          text: 'Common API',
          url: 'https://common.api.engie.com/',
        },
        {
          text: 'Contact',
          url: 'mailto:agora@engie.com',
        },
      ]}
      socialLinks={[
        {
          logo: {
            alt: 'yammer',
            path: YammerIcon,
          },
          url: 'https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiI1ODE0MjcxMTgwOCJ9/all',
        },
      ]}
    />
  );
};
export { Footer };
